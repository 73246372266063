const IconNews = ({ size, fill, ...props }) => {
  // width={size} height={size} {...props}
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width={size} height={size} {...props}>
      <line x1='11.5' x2='30.5' y1='16.5' y2='16.5' fill='none' stroke='currentColor' strokeLinecap='round'
            strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='3' />
      <line x1='25' x2='30.5' y1='24.5' y2='24.5' fill='none' stroke='currentColor' strokeLinecap='round'
            strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='3' />
      <line x1='25' x2='30.5' y1='31.5' y2='31.5' fill='none' stroke='currentColor' strokeLinecap='round'
            strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='3' />
      <rect width='7' height='7' x='11.5' y='24.5' fill='none' stroke='currentColor' strokeLinecap='round'
            strokeLinejoin='round' strokeMiterlimit='10' strokeWidth='3' />
      <path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10'
            strokeWidth='3' d='M5.5,26V10.5c0-1.7,1.3-3,3-3h25c1.7,0,3,1.3,3,3v4.3' />
      <path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10'
            strokeWidth='3' d='M39.5,40.5h-30c-2.2,0-4-1.8-4-4v-3.9' />
      <path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit='10'
            strokeWidth='3' d='M36.5,20.5v17c0,1.7,1.3,3,3,3h0c1.7,0,3-1.3,3-3V13.3' />
    </svg>
  );
};

export default IconNews;
