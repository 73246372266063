import style from './style.module.css';
import { Button } from '@nextui-org/react';
import useSWR from 'swr';
import { invitationService } from '@/services/invitation.service';
import { useRouter } from 'next/router';

export default function Invitations() {
    //API
    const { data: invitations } = useSWR(`/invitations?user_invite=true`, invitationService.fetchWrapperGetEmpty);
    const router = useRouter();
    if (!invitations || invitations?.length === 0) return null;

    return (
      <>
          {invitations && invitations?.length > 0 && (
            <div className={style.notification}>
                <div className={style.maxWidthHolder}>
                        <div className={style.text}>
                            <span>Someone invited you to join a team!</span>
                            <span className={style.subText}>Accept or decline the invitation to remove this notification.</span>
                        </div>
                    <div className={style.actions}>
                        <Button size='sm' rounded onClick={() => router.push('/account/invitations')} color={'white'}>See
                            invite</Button>
                    </div>
                </div>
            </div>
          )}
      </>
    );
}
