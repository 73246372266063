import { Button } from '@nextui-org/react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { productService } from '@/services/product';

export default function ButtonFreeTrial({ endTime }) {
  const [loading, setLoading] = useState(false);

  const isEndingSoon = useMemo(() => dayjs(endTime).diff(dayjs(), 'day') <= 1, [endTime]);
  const isExpired = useMemo(() => (!endTime || dayjs(endTime).diff(dayjs(), 'day') <= 0), [endTime]);


  function openPortal() {
    setLoading(true);
    productService.createPortalLink({type: 'payment_method_update', returnUrl: '/feed'}).then(({ url }) => {
      window.location?.assign(url);
    }).catch(err => {
      console.log('ERROR IS', err);
    }).finally(() => {
      setLoading(false);
    });
  }
  return (
    <Button
      size={'sm'}
      color={'primary'}
      onClick={openPortal}
      isLoading={loading}
      radius={'sm'}
      fullWidth={true}
      className={clsx(
        isExpired && 'text-white bg-danger-500 border-danger-600',
        !isExpired && 'p-1 text-black/80 dark:text-white/80 bg-stone-50 border-stone-200 dark:bg-stone-800 dark:border-stone-600',
        'shadow-none font-semibold mr-4 border-1 px-1'
      )}
    >
      <div className={'w-full'}>
        <span>
          {!isExpired && (
            <div className={'flex items-center justify-between'}>
              {!isEndingSoon && (
                <span className={'flex items-center space-x-1'}>
               <div
                 className={'w-5 h-5 flex items-center justify-center bg-primary-50 border-primary-200 border-1 text-primary-600 leading-none rounded-md'}>{dayjs(endTime).diff(dayjs(), 'day')}</div>
               <span>
                 day{dayjs(endTime).diff(dayjs(), 'day') > 1 && 's'} left on trial
               </span>
             </span>
              )}
              {isEndingSoon && (
                <span className={'flex items-center space-x-1'}>
               <div
                 className={'px-0.5 h-5 flex items-center justify-center bg-warning-50 border-warning-200 border-1 text-warning-600 leading-none rounded-md'}>{dayjs(endTime).diff(dayjs(), 'hour')}</div>
               <span>
                 hour{dayjs(endTime).diff(dayjs(), 'hour') > 1 && 's'} left on trial
               </span>
             </span>
              )}
              <div className={clsx('text-xs text-white rounded-md p-1', isEndingSoon ? 'bg-warning-500' : 'bg-primary-500')}>
                Add billing
              </div>
            </div>
          )}
          {isExpired && 'Free trial ended, upgrade to continue'}
       </span>
      </div>
    </Button>
  );
}
