import { useState } from 'react';
import { ChevronLeft, ChevronRight, X } from 'react-feather';
import { useAnnouncements } from '@/utils/useAnnouncements';
import { Button, Chip } from '@nextui-org/react';
import clsx from 'clsx';
import { useSpace } from '@/utils/useSpace';

export default function AppAnnouncements() {
  const { open: openAnnouncements, announcements, hideAnnouncement } = useAnnouncements();
  const [currentIndex, setCurrentIndex] = useState(0);
  const {id_space} = useSpace()

  async function hideAppAnnouncement(item) {
    hideAnnouncement(item);
    setCurrentIndex(0);
  }

  function openStatusPage() {
    window.open('https://status.breakcold.com', '_blank');
  }

  const item = announcements?.[currentIndex]; // Get the current item based on currentIndex
  if (!item) return null; // Don't render if there is no item

  return (
    <div className={clsx(
      'flex w-full px-4 py-2 border-b-1 ',
      {
        'bg-warning-50 border-warning-200': item.type === 'incident' || item.color === 'warning',
        'bg-primary-50 border-primary-200': item.type === 'patch-note' || item.type === 'custom'
      }
    )}>
      {announcements?.length > 1 && (
        <div className={'flex flex-row items-center justify-center mr-2'}>
          <Button size={'sm'} isIconOnly={true} variant={'light'} isDisabled={currentIndex === 0}
                  onClick={() => setCurrentIndex(currentIndex - 1)}><ChevronLeft /></Button>
          <span
            className={'min-w-[30px] text-sm text-black/80 dark:text-white/80'}>{currentIndex + 1} / {announcements.length}</span>
          <Button size={'sm'} isIconOnly={true} variant={'light'}
                  isDisabled={currentIndex === announcements.length - 1}
                  onClick={() => setCurrentIndex(currentIndex + 1)}><ChevronRight /></Button>
        </div>
      )}

      <div className={'flex mr-auto justify-center flex-col flex-1'}>
        <div className={'items-center flex'}>
          {item.type && (
            <Chip size={'sm'} className={'mr-2'} variant={'solid'}
                  color={{ 'patch-note': 'primary', 'incident': 'warning', 'custom': 'primary' }[item.type]}>
              {item.type === 'patch-note' && 'Patch Note'}
              {item.type === 'incident' && 'Incident'}
              {item.type === 'custom' && 'Announcement'}
            </Chip>
          )}
          <span className={'text-xl font-semibold'}>{item?.title}</span>
        </div>
        <div className={'text-sm text-black/80 dark:text-white/80'}>{item.description}</div>
      </div>
      <div className={'ml-4 flex items-center justify-center'}>
        {item.type === 'patch-note' && (
          <Button size='sm' onClick={openAnnouncements} color='primary'>Read patch note</Button>
        )}
        {item.type === 'incident' && (
          <Button color={'warning'} size='md' onClick={openStatusPage}>
            View details
          </Button>
        )}
        {item.type === 'custom' && (
          <Button color={'primary'} size='sm' onClick={() => window.open(item?.button_url, '_blank')}>
            {item?.button_text}
          </Button>
        )}

        {!item.hideClose && (
          <Button variant={'light'} isIconOnly={true} size='sm' onClick={() => hideAppAnnouncement(item)} color={{
            'patch-note': 'primary',
            'incident': 'warning',
            'warning': 'default',
            'custom': 'primary'
          }[item?.color ?? item.type]}>
            <X />
          </Button>
        )}
      </div>
    </div>
  );
}
