import { useEffect, useState } from 'react';
import Invitations from '@/components/layer/invitations';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import AppAnnouncements from '@/components/annoucement';
import Sidebar from '@/components/sidebar';

const MobileLayout = dynamic(() => import('@/components/mobileLayout'), { ssr: false });

export default function Layout({
                                 subNav,
                                 children,
                                 mainStyle
                               }) {
  const [isMobile, setIsMobile] = useState(false);
  const router = useRouter();


  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    const redirectUrl = localStorage.getItem('login_redirect');
    if (redirectUrl) {
      //if there is a redirect url, remove it from localstorage and redirect to it
      localStorage.removeItem('login_redirect');
      router.push(redirectUrl);
    }
  }, []);


  if (isMobile) {
    return <MobileLayout />;
  }


  return (
    <div className={'flex flex-1'}>
      <Sidebar />
      <main style={mainStyle} className={'flex flex-col flex-1 w-full h-screen overflow-auto'} id='skip'>
        <AppAnnouncements />
        {subNav && subNav }
        <Invitations />
        {children}
      </main>
    </div>
  );
}
