import { useEffect, useState } from 'react';

const HealthBadge = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isNotWorking, setIsNotWorking] = useState(false);

    useEffect(() => {
        //verify if id hyper-ping-badge exists and don't have any child
        if(document.getElementById('hyperping-badge') && document.getElementById('hyperping-badge').childElementCount === 0){
            init();
        }

    }, [])

    //when <div id="hyperping-badge"/> has a child, it means that the script was loaded
    useEffect(() => {
        //listen for changes in the element
        const observer = new MutationObserver(() => {
            if(document.getElementById('hyperping-badge') && document.getElementById('hyperping-badge').childElementCount > 0){
                setIsLoaded(true);
                //if the number of child is superior at 1, keep only the first child and remove the others
                if(document.getElementById('hyperping-badge').childElementCount > 1){
                    const children = document.getElementById('hyperping-badge').children;
                    for(let i = 1; i < children.length; i++){
                        document.getElementById('hyperping-badge').removeChild(children[i]);
                    }
                }
            }
        });
        observer.observe(document.getElementById('hyperping-badge'), { childList: true });

        return () => {
            observer.disconnect();
        }
    }, [])

    function init(){
        if(isLoaded){
            return;
        }
        try {
            Hyperping.init({
                "statuspage": "https://status.breakcold.com",
                "border": "none",
                "borderColor": "#30363D",
                "uptime": false,
                "dot": true,
                "dotSize": 11,
                "isNeutral": false,
                "dotOk": "#2BAC76",
                "dotIncident": "#FFAF36",
                "dotOutage": "#E95858",
                "dotMaintenance": "#0070F3",
                "dotNeutral": "#0070F3",
                "operational": "All systems normal",
                "incident": "Under investigation",
                "outage": "System outage",
                "maintenance": "Under maintenance"
            });
            setIsNotWorking(false);
        }
        catch (e) {
            console.error("Failed to initialize Hyperping: ", e);
            setIsNotWorking(true);
        }
    }

    return (
      <>
          <div  id="hyperping-badge"/>
          {(!isLoaded && !isNotWorking) && ("Loading system status...")}
          {isNotWorking && ("System status is not available")}
      </>
    );
};

export default HealthBadge;
