import s from '@/components/ui/Navbar/Navbar.module.css';
import { NovuProvider, PopoverNotificationCenter } from '@novu/notification-center';
import { useSpace } from '@/utils/useSpace';
import { useUser } from '@/utils/useUser';
import { useRouter } from 'next/router';
import useTheme from '@/utils/useTheme';
import { Bell } from 'react-feather';
import clsx from 'clsx';

const NOVU_THEME = {
  dark: {
    // Dark Theme Props
    loaderColor: '#0070f3',
    layout: {
      background: '#111',
      boxShadow: '0 8px 30px rgba(0,0,0,.10)'
    },
    notificationItem: {
      unseen: {
        fontColor: '#fff',
        timeMarkFontColor: '#fff',
        background: 'black',
        boxShadow: '0 8px 20px rgba(0,0,0,.04)',
        notificationItemBeforeBrandColor: 'black'
      },
      seen: {
        fontColor: 'white',
        timeMarkFontColor: '#999',
        background: '#181818'
      }
    }
  },
  light: {
    loaderColor: '#0070f3',
    layout: {
      background: '#fbfbfb',
      boxShadow: '0 8px 30px rgba(0,0,0,.10)'
    },
    notificationItem: {
      unseen: {
        fontColor: '#000',
        timeMarkFontColor: '#000',
        background: 'white',
        boxShadow: '0 8px 20px rgba(0,0,0,.04)',
        notificationItemBeforeBrandColor: 'white'
      },
      seen: {
        fontColor: '#212529',
        timeMarkFontColor: '#343a40',
        background: '#f1f3f5'
      }
    }
    // Light Theme Props
  },
  common: {
    IThemeNotificationListItem: {
      'unseen.background': 'red'
    }
    // Common
  }
};

export default function NotificationItem() {
  const { id_space } = useSpace();
  const { user } = useUser();
  const router = useRouter();
  const { isDark } = useTheme();


  function onNotificationClick(notification) {
    if (notification?.cta?.data?.url)
      router.push(notification.cta.data.url);
  }

  const path = router.pathname;

  const isActive = path === '';

  return (
    <NovuProvider subscriberId={`${id_space}:${user?.uid}`}
                  applicationIdentifier={process.env.NEXT_PUBLIC_NOVU_APP_ID}>
      <PopoverNotificationCenter theme={NOVU_THEME}
                                 footer={() => <div />}
                                 onNotificationClick={onNotificationClick}
                                 colorScheme={isDark ? 'dark' : 'light'}>
        {({ unseenCount }) => (
          <div
            className={clsx(
              isActive && 'text-black dark:text-white border-stone-200 dark:border-stone-700 bg-stone-100 dark:bg-stone-800',
              !isActive && 'hover:border-stone-200 dark:hover:border-stone-700 border-transparent hover:bg-stone-100 hover:text-black dark:hover:text-white dark:hover:bg-stone-800 text-black/70 dark:text-white/80',
              'cursor-pointer border-1 opacity-75 hover:opacity-100 flex items-center rounded-md px-2 py-1 font-medium text-xs')}>
            <div className={'mr-2'}>
              <Bell strokeWidth={1.5} size={12} fill={unseenCount > 0 ? true : 'none'} />
            </div>
            <div>
              Notifications
            </div>
            <div className={'text-stone-500 dark:text-stone-400 ml-auto relative'}>
              {unseenCount > 0 && (
                <div className={'text-[8px] text-white dark:text-white p-1 rounded-full bg-white dark:bg-primary-500 w-4 h-4 flex items-center justify-center'}>
                  {unseenCount > 9 ? '+9' : unseenCount}
                </div>
              )}
            </div>
          </div>
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  );
}
