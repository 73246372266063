import { ChevronDown, ChevronLeft, Compass, Mail, Truck, Zap } from 'react-feather';
import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Tooltip
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useUser } from '@/utils/useUser';
import { useAnnouncements } from '@/utils/useAnnouncements';
import IconAccounts from '@/components/icons/accounts';
import IconNews from '@/components/icons/news';
import HealthBadge from '@/components/health';
import { useRouter } from 'next/router';
import { useTheme as useNextTheme, useTheme } from 'next-themes';
import Link from 'next/link';
import clsx from 'clsx';
import SpaceSwitch from '@/components/spaceSwitch';
import { useSpace } from '@/utils/useSpace';
import Lists from '@/components/lists';
import ButtonFreeTrial from '@/components/ui/button-free-trial';
import NotificationItem from '@/components/sidebar/notification';

export default function Sidebar() {
  const router = useRouter();
  const [activeSidebar, setActiveSidebar] = useState('main');
  const { spaces, subscription } = useSpace();
  const { is_admin } = useUser();


  const sidebar = [
    {
      label: 'Leads',
      items: [
        {
          name: 'Leads',
          icon: 'home',
          link: '/leads'
        },
        {
          name: 'Pipeline',
          icon: 'user',
          link: '/pipeline'
        },
        {
          name: 'Campaigns',
          icon: 'cog',
          link: '/campaigns',
          isPremium: true

        }
      ]
    },
    {
      label: 'Feed',
      items: [
        {
          name: 'Tasks',
          icon: 'home',
          link: '/tasks'
        },
        {
          name: 'Inbox',
          icon: 'user',
          link: '/inbox'
        },
        {
          name: 'Engagement',
          icon: 'cog',
          link: '/feed'
        }
      ]
    }
  ];

  const bottomSidebar = [
    {
      'name': 'Invite teammates',
      'icon': 'user',
      'link': '/settings/team'
    },
    {
      name: 'Tips & tutorials',
      icon: 'cog',
      link: '/'
    },
    {
      name: 'Settings',
      icon: 'cog',
      link: '/accounts'
    }
  ];

  const settingsSidebar = [
    {
      label: 'Account',
      items: [
        {
          link: '/settings/account',
          name: 'Profile'
        },
        {
          link: '/settings/account/security',
          name: 'Security'
        },
        {
          link: '/settings/account/invitations',
          name: 'Invitations'
        }
      ]
    },
    {
      label: 'Workspace',
      items: [
        {
          link: '/settings',
          name: 'General'
        },
        {
          link: '/accounts',
          name: 'Accounts'
        },
        {
          link: '/settings/team',
          name: 'Members'
        },
        {
          link: '/settings/integrations',
          name: 'Integrations'
        }
      ]
    },
    {
      label: 'Developer',
      items: [
        {
          link: '/settings/developer/webhook',
          name: 'Webhook'
        },
        {
          link: '/settings/developer/api',
          name: 'API Keys'
        }
      ]
    },
    {
      label: 'Billing',
      items: [
        {
          link: '/settings/subscription',
          name: 'Plans'
        },
        {
          link: '/settings/billing',
          name: 'Invoices'
        }
      ]
    },
    {
      label: 'Other',
      hidden: spaces?.length === 1,
      items: [
        {
          link: '/settings/danger-zone',
          color: 'danger',
          name: 'Leave space'
        }
      ]
    },
    {
      label: 'Admin',
      hidden: !is_admin,
      items: [
        {
          link: '/thuruht',
          color: 'warning',
          name: 'Panel'
        }
      ]
    }
  ];

  useEffect(() => {
    //if the router is on the settings page, set the active sidebar to settings
    if (window.location.pathname.includes('/settings')) {
      setActiveSidebar('settings');
    }
  }, []);


  const isSettings = router.pathname.startsWith('/settings') || router.pathname.startsWith('/accounts');
  const isThuruht = router.pathname.includes('/thuruht');
  const isCampaigns = router.pathname.includes('/campaigns/');
  const isMain = (!isSettings && !isThuruht);

  return (
    <div
      className={'w-[250px] h-[100VH] flex pb-2 bg-white dark:bg-black border-r-1 border-stone-200 dark:border-stone-700  flex-col'}>
      <div className={'p-2 border-b-1 border-stone-200 dark:border-stone-700'}>
        <UserDropdown />
      </div>
      {isSettings && (
        <div onClick={() => router.push('/feed')}
             className={'text-black/60 dark:text-white/60 hover:text-black/100 hover:dark:text-white/100 space-x-2 items-center flex px-2 py-2 border-b-1 border-stone-200 dark:border-stone-700 hover:bg-stone-100 cursor-pointer transition-all duration-100 dark:hover:bg-stone-800'}>
          <ChevronLeft size={18} />
          <span className={'font-medium text-sm'}>Settings</span>
        </div>
      )}
      <div className={'overflow-x-auto'}>
        <div className={'flex flex-col px-2'}>
          <div className={'mt-2'}>
            <NotificationItem/>
          </div>
          {isMain && sidebar.map((item, index) => {
            if (item.items) {
              return (
                <GroupSidebar hidden={item.hidden} key={index} items={item.items} label={item.label} />
              );
            }
            return (
              <LinkSidebar key={index} {...item} />
            );
          })}
          {isSettings && settingsSidebar.map((item, index) => {
            if (item.items) {
              return (
                <GroupSidebar hidden={item.hidden} key={index} items={item.items} label={item.label} />
              );
            }
            return (
              <LinkSidebar key={index} {...item} />
            );
          })}
        </div>
        {isMain && (
          <div className={'mt-4'}>
            <Lists isCampaigns={isCampaigns} vertical />
          </div>
        )}
      </div>
      <div className={'flex flex-col px-2 mt-auto'}>
        {isMain && bottomSidebar.map((item, index) => {
          if (item.items) {
            return (
              <GroupSidebar hidden={item.hidden} key={index} items={item.items} label={item.label} />
            );
          }
          return (
            <LinkSidebar key={index} {...item} />
          );
        })}
      </div>
      {(isMain && subscription?.status === 'trialing') && (
        <div className={'flex flex-col px-2 mt-2 w-full'}>
          <ButtonFreeTrial endTime={subscription.trial_end} />
        </div>
      )}
      <div className={'flex flex-col px-2'}>
        {isSettings && (
          <SpaceSwitch />
        )}
      </div>
    </div>
  );
}


function LinkSidebar({ color, name, icon, link, items, label, isPremium, hidden }) {
  const router = useRouter();
  const path = router.pathname;

  const isActive = path === link;

  if (hidden) return null;

  return (
    <Link href={link}
          className={clsx(
            color === 'danger' && 'text-danger-500 dark:text-danger-400 hover:text-danger-600 dark:hover:text-danger-500',
            isActive && 'text-black dark:text-white border-stone-200 dark:border-stone-700 bg-stone-100 dark:bg-stone-800',
            !isActive && 'hover:border-stone-200 dark:hover:border-stone-700 border-transparent hover:bg-stone-100 hover:text-black dark:hover:text-white dark:hover:bg-stone-800 text-black/70 dark:text-white/80',
            'cursor-pointer border-1 flex items-center rounded-md px-2 py-1.5 font-medium text-sm')}>
      <div className={''}>
        {name}
      </div>
      {isPremium && (
        <Tooltip color={'primary'} showArrow placement={'right'}
                 content={'This feature is only available in Breakcold+'}>
          <div className={'text-stone-500 dark:text-stone-400 ml-auto'}>
            <Zap fill={'currentColor'} size={14} />
          </div>
        </Tooltip>
      )}
    </Link>
  );
}


function GroupSidebar({ hidden, items, label }) {
  if (hidden) return null;

  return (
    <nav className={'flex flex-col mt-4'}>
      {label &&
        <div className={'px-2 text-xs mb-1.5 uppercase text-stone-400 dark:text-stone-500 font-semibold'}>{label}</div>
      }
      <div className={'flex flex-col gap-0.5'}>
        {items.map((item, index) => {
          return (
            <LinkSidebar key={index} {...item} />
          );
        })}
      </div>
    </nav>
  );
}


function UserDropdown() {
  const { signOut, user, userDetails, is_admin } = useUser();
  const router = useRouter();
  const { open: openAnnouncements } = useAnnouncements();
  const { setTheme } = useNextTheme();
  const { theme } = useTheme();

  function handleDropdownAction(event) {
    switch (event) {
      case 'profile':
        router.push('/settings/account/');
        break;
      case 'accounts':
        router.push('/accounts');
        break;
      case 'invitations':
        router.push('/settings/account/invitations/');
        break;
      case 'product_update':
        openAnnouncements();
        break;
      case 'roadmap':
        window.open('https://roadmap.breakcold.com', '_blank');
        break;
      case 'thuruht':
        router.push('/thuruht/');
        break;
      case 'logout':
        signOut();
        break;
      case 'health':
        window.open('https://status.breakcold.com/', '_blank');
        break;
    }
  }

  function handleThemeChange(e) {
    switch (e?.target?.value) {
      case 'system':
        setTheme('system');
        break;
      case 'dark':
        setTheme('dark');
        break;
      case 'light':
        setTheme('light');
        break;
    }

  }

  return (
    <div className={''}>
      <Dropdown
        showArrow
        radius='sm'
        classNames={{
          base: 'p-0 border-1 border-divider bg-background',
          arrow: 'bg-default-200'
        }}
      >
        <DropdownTrigger>
          <div
            className={'space-x-2 cursor-pointer justify-between hover:bg-stone-100 dark:hover:bg-stone-900 flex p-2 rounded-lg border-1 border-transparent hover:border-stone-200 dark:hover:border-stone-700'}>
            <div className={'space-x-2 flex'}>
              <Avatar
                as='button'
                size='sm'
                src={userDetails?.avatar_url}
              />
              <div className={'flex flex-col'}>
                <span className={'text-sm font-medium leading-tight text-black/80 dark:text-white/80'}>
                {userDetails?.full_name ?? 'No Name'}
                </span>
                <span className={'text-xs leading-tight text-black/60 dark:text-white/60'}>
                {userDetails?.handle ? `@${userDetails?.handle}` : user?.email ?? 'Unknown'}
              </span>
              </div>
            </div>
            <div className={'flex items-center'}>
              <ChevronDown size={16} />
            </div>
          </div>
        </DropdownTrigger>
        <DropdownMenu
          aria-label='Custom item styles'
          className='p-2'
          onAction={handleDropdownAction}
          itemClasses={{
            base: [
              'rounded-lg',
              'text-default-500',
              'transition-opacity',
              'data-[hover=true]:text-foreground',
              'data-[hover=true]:bg-default-100',
              'dark:data-[hover=true]:bg-default-50',
              'data-[pressed=true]:opacity-70',
              'data-[focus-visible=true]:ring-default-200'
            ]
          }}
        >
          <DropdownSection aria-label='Profile & settings' showDivider>
            <DropdownItem
              key='profile'
              startContent={<IconAccounts size={16} />}
            >
              My settings
            </DropdownItem>
            <DropdownItem
              startContent={<Mail size={16} />}
              key='invitations'>
              Invitations
            </DropdownItem>
          </DropdownSection>
          <DropdownSection aria-label='Products' showDivider>
            <DropdownItem startContent={<IconNews size={16} />}
                          key='product_update'>
              Product update
            </DropdownItem>
            <DropdownItem startContent={<Truck size={16} />}
                          key='roadmap'>
              Roadmap
            </DropdownItem>
          </DropdownSection>
          {is_admin && (
            <DropdownSection aria-label='Products' showDivider>
              <DropdownItem startContent={<Compass size={16} />}
                            key='thuruht'>
                Thuruht
              </DropdownItem>
            </DropdownSection>
          )}

          <DropdownSection showDivider aria-label='Others'>
            <DropdownItem
              isReadOnly
              key='theme'
              className='cursor-default'
              endContent={
                <select
                  value={theme}
                  onChange={handleThemeChange}
                  className='z-10 outline-none w-16 py-0.5 rounded-md text-tiny group-data-[hover=true]:border-default-500 border-small border-default-300 dark:border-default-200 bg-transparent text-default-500'
                  id='theme'
                  name='theme'
                >
                  <option value={'system'} key={'system'}>System</option>
                  <option value={'dark'} key={'dark'}>Dark</option>
                  <option value={'light'} key={'light'}>Light</option>
                </select>
              }
            >
              Theme
            </DropdownItem>
            <DropdownItem key='logout'>Log Out</DropdownItem>
          </DropdownSection>
          <DropdownSection aria-label='Health' className={'p-0 mb-0'}>
            <DropdownItem key='health'>
              <HealthBadge />
            </DropdownItem>
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

