import getConfig from '../utils/getConfig'
import { fetchWrapper } from './fetch-wrapper';

const { apiUrl } = getConfig();
const baseUrl = `${apiUrl}/invitation`;

export const invitationService = {
    createInvitation,
    getInvitations,
    getUserInvitations,
    deleteInvitation,
    acceptInvitation,
    acceptUserInvitation,
    fetchWrapperGet: url => fetchWrapper.get(`${baseUrl}${url}`),
    fetchWrapperGetEmpty: url => fetchWrapper.get(`${apiUrl}${url}`),
    declineInvitation
}

// function acceptInvitation(code) {
//     const payload = `invitationCode=${code}`;
//
//     return fetchWrapper.get(`${baseUrl}/accept?${payload}`);
// }


function createInvitation(id_space, data) {
    let payload = `id_space=${id_space}`;
    return fetchWrapper.post(`${baseUrl}?${payload}`, data);
}

function getInvitations(id_space){
    let payload = `id_space=${id_space}`;
    return fetchWrapper.get(`${baseUrl}s?${payload}`);
}

function getUserInvitations(){
    return fetchWrapper.get(`${baseUrl}s?user_invite=true`);
}

function deleteInvitation(id_space, invitationId){
    let payload = `id_space=${id_space}&id_invitation=${invitationId}`;
    return fetchWrapper.delete(`${baseUrl}?${payload}`);
}

function acceptInvitation(invitationId) {
    return fetchWrapper.post(`${baseUrl}/${invitationId}/accept` )
}


function acceptUserInvitation(invitationId){
    return fetchWrapper.post(`${apiUrl}/user/invitation/${invitationId}`);
}

function declineInvitation(invitationId) {
    return fetchWrapper.post(`${baseUrl}/${invitationId}/decline` )
}

function getForLinkedin(id_space, profileId) {

    return fetchWrapper.post(`${baseUrl}/linkedin`, {
        id_space: id_space,
        profileId
    });
}
